class AstTrimmer {
    parse_targets(vm_json_object) {
        let target_trees = {};
        for (let target of vm_json_object.targets) {
            target_trees[target.name] = this.parse_target_ast(target);
        }
        const scratch_ast_json = JSON.stringify(target_trees);

        return target_trees;
    };

    parse_target_ast(target_ast) {
        let nodes = {};

        let roots = []; // the root block of the current target
        let children = {}; // the children by block_id -> [block_ids]
        let successors = {}; // the successors of blocks by block_id -> block_id

        let node_count = 0;

        const parse_inputs_ast = (input_values) => {
            if (Array.isArray(input_values[1])) {
                // todo handle further arguments
                const [kind_id, value, _broadcast_id, _xcoord, _ycoord] = input_values[1];

                // https://en.scratch-wiki.info/wiki/Scratch_File_Format
                const kind_id_to_str = (id) => {
                    if (id == 4) {
                        return 'number'
                    };
                    if (id == 5) {
                        return 'positive number'
                    };
                    if (id == 6) {
                        return 'postive integer'
                    };
                    if (id == 7) {
                        return 'integer'
                    };
                    if (id == 8) {
                        return 'angle'
                    };
                    if (id == 9) {
                        return 'color'
                    };
                    if (id == 10) {
                        return 'string'
                    };
                    if (id == 11) {
                        return 'broadcast'
                    };
                    if (id == 12) {
                        return 'variable'
                    };
                    if (id == 13) {
                        return 'list'
                    };
                    return 'unkonwn kind';
                }
                return {
                    kind: kind_id_to_str(kind_id),
                    value: value
                };
            } else {
                return {
                    kind: 'block_reference',
                    value: input_values[1]
                };
            }
        }

        const parse_block_ast = (block_ast) => {
            const opcode = block_ast.opcode;
            const parent_id = block_ast.parent;
            const next_id = block_ast.next;
            const top_level = block_ast.topLevel;
            let inputs = [];
            if (block_ast.inputs) {
                for (const input_name of Object.getOwnPropertyNames(block_ast.inputs)) {
                    const input_values = block_ast.inputs[input_name];
                    inputs.push(parse_inputs_ast(input_values));
                }
            }

            return {
                opcode: opcode,
                parent_id: parent_id,
                top_level: top_level,
                next_id: next_id,
                inputs: inputs
            };
        };

        for (const block_ast_name of Object.getOwnPropertyNames(target_ast.blocks)) {
            node_count += 1;

            const block_ast = target_ast.blocks[block_ast_name];
            if (block_ast ) {
                const block_data = parse_block_ast(block_ast);
                if (block_data.parent_id === null) {
                    roots.push(block_ast_name);
                } else {
                    children[block_data.parent_id] ||= [];
                    children[block_data.parent_id].push(block_ast_name);
                }
                if (block_data.next_id) {
                    successors[block_ast_name] = block_data.next_id;
                }
                nodes[block_ast_name] = block_data;
            } else {
                console.error("block_ast is undefined")
            }
        }

        let emitted = {};

        const flatten_tree = (node_id) => {
            if (node_id) {
                emitted[node_id] = true;
                const current = nodes[node_id];
                const opcode = current.opcode;
                const inputs = current.inputs.map((input) => {
                    if (input.kind == "block_reference") {
                        // replace refrence, by the opcode value, this should be good enough for the tutor
                        if (nodes[input.value]) {
                            input.value = nodes[input.value].opcode;
                        } else {
                            input.value = "unresolved reference";
                        }
                    }

                    return input;
                });
                const child_ops = (children[node_id]) ? children[node_id].map((child_id) => {
                    return flatten_tree(child_id)
                }) : [];
                return [opcode, inputs, child_ops];
            }
        }

        let result = [];
        for (let root of roots) {
            let node_id = root;
            while (node_id) {
                if (!emitted[node_id]) {
                    result.push(flatten_tree(node_id));
                }
                node_id = successors[node_id];
            }
        }

        return result;
    }


}

export  const ast_trimmer = new AstTrimmer();

